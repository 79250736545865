import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCapitalizeWords]'
})
export class CapitalizeWordsDirective {

  @Input() capitalizeStyle: 'normal' | 'alluppercase' = 'normal';

  constructor(private el: ElementRef, private control: NgControl, private renderer: Renderer2) {}

  @HostListener('input') onInput() {
    const input = this.el.nativeElement;
    let transformedValue = this.capitalizeWords(input.value);
    this.control!.control!.setValue(transformedValue);
    this.renderer.setProperty(input, 'value', transformedValue);
  }

  private capitalizeWords(value: string): string {
    if (this.capitalizeStyle === 'alluppercase') {
      return value.toUpperCase();
    }
    return value
      .split(/(\s+|[,])/g)
      .map((word) => {
        if (word.trim() === '') {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('');
  }
}
