import { Directive, HostListener, Input } from '@angular/core';
import { APIService } from 'src/app/modules/common/services/api.service';
import { CommonService } from 'src/app/modules/common/services/common.service';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';
import { apiUrl } from './api-url.enum';
import { debounceTime, Subject } from 'rxjs';

@Directive({
    selector: '[appRateSheet]'
})
export class PriceListDirective {
    @Input() form: any;
    @Input() priceControlName: any;
    @Input() uomControlName: any;
    @Input() productVariantControlName: any;
    @Input() skuControlName: any;
    @Input() qtyControlName: any;
    @Input() formarray: any
    @Input() sales: any
    @Input() rateSheetApply:any = true
    private qtySubject: Subject<any> = new Subject<any>();
    data: any
    constructor(
        private commonService: CommonService,
        private organizationService: OrganizationService,
        private api: APIService) {
        this.qtySubject.pipe(
            debounceTime(800)
        ).subscribe(() => {
            this.data = this.organizationService.getComponentData('rateSheet', 'rateSheetValue');
            if (this.qtyControlName && this.data) {
                this.checkPriceList(this.data.userID, this.data.priceListID);
            }
        });
    }
    @HostListener('update', ['$event'])
    onUpdate(event: any) {
        this.data = this.organizationService.getComponentData('rateSheet', 'rateSheetValue')
        if (this.uomControlName) {
            this.checkPriceList(this.data?.userID, this.data?.priceListID)
        }

        if (this.productVariantControlName) {
            this.checkPriceList(this.data?.userID, this.data?.priceListID)
        }
        if (this.skuControlName) {
            this.checkPriceList(this.data?.userID, this.data?.priceListID)
        }
        if (this.formarray) {
            this.checkPriceList(this.data?.userID, this.data?.priceListID)
        }
    }

    @HostListener('input', ['$event'])
    onInput(event: any) {
        this.qtySubject.next(undefined);
    }

    changePrice(form: any, value: any) {
        form.get('price')?.setValue(value)
    }

    getRateSheetData() {
        let payload;
        let data = this.formarray ?? this.form
        if (this.formarray) {
            let itemForm: any = []
            data?.controls?.forEach((item: any) => {
                const uomKey = item?.get('alternate_uom')?.value ? 'alternate_uom' : 'uom';
                if (item?.get('sales_product')?.value) {
                    let uom;
                    if(item?.get('alternate_uom')?.value){
                        uom = item?.get('item')?.value?.uom_conversion?.find((data:any)=>data.id === item?.get('alternate_uom')?.value)
                    } 
                    const value = {
                        'id': item?.get('sales_product')?.value,
                        'qty': parseFloat(item?.get('qty')?.value) ?? 0,
                        'product_variant': item.get('product_variant')?.value,
                        'uom': item?.get('alternate_uom')?.value ? uom?.uom : item?.get('uom')?.value,
                        'alternate_uom' : item?.get('alternate_uom')?.value ?? null
                    }
                    itemForm.push(value)
                }
            })
            payload = {
                price_list_id: this.data.priceListID,
                items: itemForm
            }
        } else {
            if (this.form.get('sales_product')?.value) {
                const uomKey = this.form?.get('alternate_uom')?.value ? 'alternate_uom' : 'uom';
                let uom;
                    if(this.form?.get('alternate_uom')?.value){
                        uom = this.form?.get('item')?.value?.uom_conversion?.find((item:any)=>item.id === this.form?.get('alternate_uom')?.value)
                    }
                payload = {
                    price_list_id: this.data.priceListID,
                    items: [{
                        'id': this.form.get('sales_product')?.value,
                        'qty': parseFloat(this.form.get('qty')?.value) ?? 0,
                        'product_variant': this.form.get('product_variant')?.value,
                        'uom': this.form?.get('alternate_uom')?.value ? uom?.uom : this.form.get('uom')?.value,
                        'alternate_uom': this.form?.get('alternate_uom')?.value ?? null
                    }
                    ]
                }
            }
        }
        if (payload?.items?.length > 0) {
            this.api.createItem(apiUrl.get_rate_sheet, payload).subscribe({
                next: (value) => {
                    if (value?.results?.length > 0) {
                        if (this.formarray) {
                            this.formarray?.controls?.forEach((item: any) => {
                                
                                const match = value.results.find((value: any) => {
                                    if(value?.alternate_uom && item?.value?.alternate_uom === value?.alternate_uom){
                                        return this.commonService.generateUNQID('id-alternate_uom-product_variant-qty', value) ===  
                                        this.commonService.generateUNQID('sales_product-alternate_uom-product_variant-qty', item?.value)
                                    }else{
                                       return this.commonService.generateUNQID('id-uom-product_variant-qty', value) ===  
                                        this.commonService.generateUNQID('sales_product-uom-product_variant-qty', item?.value)
                                    }
                                }
                                )
                                if (match) {
                                    this.changePrice(item, match?.price ?? 0)
                                }
                            })
                        } else {
                            this.changePrice(this.form, value?.results[0] ? value?.results[0]?.price :0 )
                        }
                    }else{
                        if(this.sales){
                            if (this.formarray) {
                                this.formarray?.controls?.forEach((item: any) => {
                                    if (item) {
                                        this.changePrice(item, item.get('default_price')?.value ?? 0)
                                    }
                                })
                            } else {
                                this.changePrice(this.form, this.form.get('default_price')?.value ?? 0 )
                            }
                        }else{
                            if (this.formarray) {
                                this.formarray?.controls?.forEach((item: any) => {
                                    if (item) {
                                        this.changePrice(item, item.get('default_price')?.value ?? 0)
                                    }
                                })
                            } else {
                                this.changePrice(this.form, this.form.get('default_price')?.value ?? 0 )
                            }
                        }
                    }
                }, error(err) {

                }, complete() {

                },
            })
        }

    }

   async checkPriceList(userId: any, priceListId: any) {
        let user;
        let priceList;
        if (priceListId) {
            priceList = this.data.priceListValue.find((item: any) => item?.value === priceListId)
        }
        if (userId) {
            user = this.data.userdata?.find((item: any) => item.id === userId)
        }
        if (user && priceList) {
            const data = this.commonService.checkPriceList(user, priceList, this.sales ? 'customers' : 'vendors')
            if (data && this.rateSheetApply) {
                this.getRateSheetData()
            } else {
                console.log('Oppppssss Sorry!');

            }
        }
    }

}
