import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, searchKey?: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    if (searchKey) {
      return items.filter(item =>
        item[searchKey].toLowerCase().includes(searchText)
      );
    } else {
      return items.filter(item => {
        return Object.keys(item).some(key =>
          item[key] != null && item[key].toString().toLowerCase().includes(searchText)
        );
      });
    }
  }


}