import { Injectable } from '@angular/core';
import { en_US } from 'src/app/_metronic/kt/_utils/en_us';
import { SweetAlertOptions } from 'sweetalert2';
import { AlertService } from './alert.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  constructor(private alert:AlertService) { }


  calculateItems(price: any, qty: any, data: any, hideDiscount: boolean = true) {
    let item = data;

    const formattedPrice = Math.round(parseFloat(price) * 100) / 100;
    const formattedQty = Math.round(parseFloat(qty) * 100) / 100;

    let totalPrice = formattedPrice * formattedQty;
    totalPrice = Math.round(totalPrice * 100) / 100;

    let totalDiscount = 0;
    let discountablePrice = 0;

    if (data?.discount_type && hideDiscount) {
      if (data?.discount_type === 1) {
        totalDiscount += (totalPrice * parseFloat(data?.discount)) / 100;
      } else {
        totalDiscount += parseFloat(data?.discount);
      }
    }

    totalDiscount = Math.round(totalDiscount * 100) / 100;

    discountablePrice = totalPrice - totalDiscount;

    let totalTax = 0;
    let totalPercentageTax = 0;
    let taxDetails: any = [];
    if (item?.tax_classes && Array.isArray(item.tax_classes)) {
      item.tax_classes.forEach((tax: any) => {
        if (tax?.percentage) {
          const taxPercentage = parseFloat(tax.percentage);
          let individualTax = (discountablePrice * taxPercentage) / 100;
          individualTax = Math.round(individualTax * 100) / 100;
          taxDetails.push({
            name: tax.name,
            value: taxPercentage,
            individualTax: individualTax
          });
          totalTax += individualTax;
          totalPercentageTax += taxPercentage;
        }
      });
    }
    const finalPrice = discountablePrice + totalTax;
    return {
      totalDiscount: totalDiscount,
      discountablePrice: discountablePrice,
      totalTax: totalTax,
      finalPrice: finalPrice,
      totalPercentageTax: totalPercentageTax,
      taxDetails: taxDetails
    };
  }

  calculateOC(price: any, qty: any, item: any) {
    const totalPrice = parseFloat(price) * parseFloat(qty);
    let totalTax = 0;
    let totalPercentageTax = 0;
    let taxDetails: any = [];
    if (item?.tax_classes && Array.isArray(item.tax_classes)) {
      item.tax_classes.forEach((tax: any) => {
        if (tax?.percentage) {
          const taxPercentage = parseFloat(tax.percentage);
          const individualTax = (totalPrice * taxPercentage) / 100;
          taxDetails.push({
            name: tax.name,
            value: taxPercentage,
            individualTax: individualTax
          });
          totalTax += individualTax;
          totalPercentageTax += taxPercentage;
        }
      });
    }
    const finalPrice = totalPrice + totalTax;
    return {
      totalTax: totalTax,
      finalPrice: finalPrice,
      totalPercentageTax: totalPercentageTax,
      taxDetails: taxDetails
    };
  }

  Finalcalculate(data: any) {
    let finalPrice = 0;
    let discountablePrice = 0
    let totalTax = 0
    let netOcPrice = 0
    let taxList: any = []
    data?.items?.forEach((item: any) => {
      const finalPriceData = this.calculateItems(item.price, item.qty, item);
      finalPrice += finalPriceData.finalPrice;
      discountablePrice += finalPriceData.discountablePrice,
        totalTax += finalPriceData.totalTax
      taxList.push(finalPriceData.taxDetails)
    });

    data?.taxable_other_charges?.forEach((item: any) => {
      const finalPriceData = this.calculateOC(item.amount, 1, item);
      finalPrice += finalPriceData.finalPrice;
      discountablePrice += item.amount,
        netOcPrice += item.amount,
        totalTax += finalPriceData.totalTax
      taxList.push(finalPriceData.taxDetails)
    });
    return {
      finalPrice: finalPrice,
      discountablePrice: discountablePrice,
      totalTax: totalTax,
      netOcPrice: netOcPrice,
      taxDetails: taxList
    };
  }

  combineTaxLists(data: any): any[] {
    const combined: any = {};
    for (const sublist of data) {
      for (const item of sublist) {
        const { name, value, individualTax } = item;
        if (combined[name]) {
          combined[name].value += value;
          combined[name].individualTax += individualTax;
        } else {
          combined[name] = { name, value, individualTax };
        }
      }
    }
    return Object.values(combined);
  }

  purchasedata(data: any) {
    let supplierKeyName: any = data?.supplier_details ? 'supplier_details' : `supplier_data`
    return {
      currencyName: data?.[supplierKeyName]?.currency_name ?? null
    }
  }

  checkPriceList(user:any, priceList:any, userKeyName = 'vendors') {
    if (!user || !priceList?.data) return false;
    const item = priceList.data[userKeyName];
    const address = userKeyName === 'vendors' ? 'address' : 'customer_billing_address_data'
    if (Array.isArray(item) && item.length > 0 && !item.includes(user.id)) {
      return false;
    }
    if (priceList.data.country_name?.toLowerCase() !== user[address]?.country_name?.toLowerCase()) {
      return false;
    }
    if (priceList.data.state_name && priceList.data.state_name.toLowerCase() !== user[address]?.state?.toLowerCase()) {
      return false;
    }
    if (priceList.data.city_name && priceList.data.city_name.toLowerCase() !== user[address]?.city?.toLowerCase()) {
      return false;
    }
    return {
      user: user,
      price_list: priceList
    };
  }


  adjustDateByDays(days: number, format: string = 'YYYY-MM-DD'): string {
    const today = new Date();
    const adjustedDate = new Date(today);
    adjustedDate.setDate(today.getDate() + days);
  
    function formatDate(date: Date, format: string): string {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
  
      return format
        .replace('YYYY', year)
        .replace('MM', month)
        .replace('DD', day);
    }
    return formatDate(adjustedDate, format);
  }


  storeConditions(data: any[], value: number,isBatchEnable = false): { 
    isUnique: boolean, 
    remainingQty: number, 
    isLess: boolean, 
    addressValid: boolean, 
    errorMessage: string | null 
  } {
    let errorMessage: string | null = null;
    let isUnique = true;
    let isLess = false;
    let addressValid = true;
    if (!data || data.length === 0) {
      return { isUnique: false, remainingQty: 0, isLess: false, addressValid: false, errorMessage: en_US.duplicate_error };
    }
    const ids = data.map((item: any) => item.store_name);
    const hasInvalidIds = ids.some((id: any) => !id);
    if (!hasInvalidIds) {
      if (isBatchEnable) {
        const uniqueStoreBatch = new Set(data.map((item: any) => `${item.store_name}-${item.batch_id}`));
        isUnique = uniqueStoreBatch.size === data.length;
      } else {
        const uniqueStoreNames = new Set(ids);
        isUnique = uniqueStoreNames.size === data.length;
      }
    }
    if (!isUnique || hasInvalidIds) {
      errorMessage = en_US.duplicate_error;
      return { isUnique, remainingQty: 0, isLess: false, addressValid: false, errorMessage };
    }
    const totalQty = data.reduce((acc, item) => acc + (parseFloat(item.qty) || 0), 0);
    isLess = value < totalQty;
    if (isLess) {
      errorMessage = en_US.store_quantity_error;
      return { isUnique, remainingQty: value - totalQty, isLess, addressValid: false, errorMessage };
    }
    if(value - totalQty !==  0){
      errorMessage = 'Please complete your remaining Quantity';
      return { isUnique, remainingQty: value - totalQty, isLess, addressValid: false, errorMessage };
    }
    for (const item of data) {
      if (item.address === undefined && item.store_name) {
        addressValid = false;
        errorMessage = en_US.store_address_error;
        break;
      }
    }
    return { isUnique, remainingQty: value - totalQty, isLess, addressValid, errorMessage };
  }

  checkLineItemsStoreQTY(data:any){
    let isValid = true;
    for (let i = 0; i < data.length; i++) {

      const storeQty =   data[i].store_qty
      const qty = data[i].qty;
      const itemCheck =this.storeConditions(storeQty,qty).remainingQty

      if (itemCheck !== 0) {
        isValid = false;
        break;
      }
    }
    if (!isValid) {
      const errorOptions: SweetAlertOptions = {
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        icon: 'error',
        timer: 5000,
        title: `Store quantity and Quantity must be the same for all items.`
      };
      this.alert.showAlert(errorOptions);
      return false
    }
    return true
  }

  validateCustomFieldsData(items: any[]): boolean {
    for (let i = 0; i < items.length; i++) {
      const customFieldsData = items[i].custom_fields_data;
      if (!customFieldsData || !customFieldsData.dynamic_data) {
        continue;
      }
      const requiredFields = customFieldsData.dynamic_data.filter((field: any) => field.is_required);
      const dynamicDataValue = customFieldsData.dynamic_data_value || {};
      for (let field of requiredFields) {
        const fieldKey = field.label_name.toLowerCase();
        const fieldValue = dynamicDataValue[fieldKey];
  
        if (!fieldValue || fieldValue.trim() === '') {
          return false;
        }
      }
    }
    return true;
  }

  areKeysUnique(objects: any[], keys: string[]):any {
    const uniqueKeys = new Set<string>();
    const invalidIndices: number[] = [];
    
    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      const key = keys.map(k => obj[k]).join('-');
      
      if (uniqueKeys.has(key)) {
        invalidIndices.push(i);
      } else {
        uniqueKeys.add(key);
      }
    }
    
    return {
      isUnique: invalidIndices.length === 0,
      invalidIndices: invalidIndices
    };
  }

  convertToValueLabelArray(accounts: any[]): { value: number; label: string }[] {
    let result: { value: number; label: string }[] = [];
    accounts.forEach(account => {
      result.push({
        value: account.id,
        label: account.account_name
      });
      if (account.children && account.children.length > 0) {
        result = result.concat(this.convertToValueLabelArray(account.children));
      }
    });
    return result;
  }

  generateUNQID(key: string, data: any): string {
    const keys = key.split('-');
    
    let unqid = keys.map(k => {
      const value = k.split('.').reduce((o, i) => (o && o[i] !== undefined) ? o[i] : 'false', data);
      return value === undefined || value === null ? 'false' : value;
    }).join('-');
  
    return unqid;
  }
   hsnCodeConditionalValidation(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
          return null;
        }
        const isNumeric = /^\d+$/.test(control.value);
        if (!isNumeric) {
          return { 'hsnCodeInvalid': true };
        }
        const valueLength = control.value.length;
        if (valueLength < 4 || valueLength > 8) {
          return { 'hsnCodeLength': true };
        }
          return null;
      };
    }


    getCurrentTimezone(): string {
      const offsetMinutes = new Date().getTimezoneOffset();
      const hours = Math.floor(Math.abs(offsetMinutes) / 60);
      const minutes = Math.abs(offsetMinutes) % 60;
    
      const sign = offsetMinutes > 0 ? '-' : '+';
      const formattedTimeZone = `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      
      return formattedTimeZone;
    }
}
