import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';


const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class SetUpService extends ConnectBaseService {
    getMemberPaySlipData: any;
 
   createIdentification(body: any, id?:any): Observable<any> {
        const ID:any = this.org() ? this.org() : id;   
        return this.post(`organizations/documents/?organization_id=${ID}`, body);
    }
    
    // Departments
    createDepartments(body: any, id?:any): Observable<any> {
        const ID:any = this.org() ? this.org() : id;   
        return this.post(`organizations/departments/?organization_id=${ID}`, body);
    }

    getDepartments(searchText?: string, ordering?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`organizations/departments/${params}`);
    }

    updateDepartments(body: any,id:any): Observable<any> {
        return this.put(`organizations/departments/${id}/?organization_id=${this.org()}`, body);
    }

    deleteDepartments(id: Number): Observable<any> {   
        return this.delete(`organizations/departments/${id}/?organization_id=${this.org()}`, );
    }

    // Designation
    createDesignations(body: any,id?:any): Observable<any> {
        const ID:any = this.org() ? this.org() : id;   
        return this.post(`organizations/designations/?organization_id=${ID}`, body);
    }

    getDesignations(searchText?: string, ordering?: any,all_result?:any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (all_result) {
            params += `&all_result=${all_result}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`organizations/designations/${params}`);
    }

    updateDesignations(body: any,id:any): Observable<any> {
        
        return this.put(`organizations/designations/${id}/?organization_id=${this.org()}`, body);
    }

    deleteDesignations(id: Number): Observable<any> {   
        return this.delete(`organizations/designations/${id}/?organization_id=${this.org()}`, );
    }

    // Member-Types
    createmembertype(body: any,id?:any): Observable<any> {
        const ID:any = this.org() ? this.org() : id;   
        return this.post(`organizations/member-types/?organization_id=${ID}`, body);
    }

    getMemberType(searchText?: string, ordering?: any, all_result?:boolean): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (all_result) {
            params += `&all_result=${all_result}`;
        }
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`organizations/member-types/${params}`);
    }

    updatemembertype(body: any,id:any): Observable<any> {
        return this.put(`organizations/member-types/${id}/?organization_id=${this.org()}`, body, );
    }

    deleteMemberType(id: Number): Observable<any> {   
        return this.delete(`organizations/member-types/${id}/?organization_id=${this.org()}`, );
    }

    // Work-Address
    createworkaddress(body: any, id?:any): Observable<any> {   
        const ID:any = this.org() ? this.org() : id;   
        return this.post(`organizations/work-address/?organization_id=${ID}`, body, );
    }

    getWorkAddress(searchText?: string, ordering?: any , all_result?:boolean): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (all_result) {
            params += `&all_result=${all_result}`;
        }
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`organizations/work-address/${params}`);
    }

    updateworkaddress(body: any,id:any): Observable<any> {
        return this.put(`organizations/work-address/${id}/?organization_id=${this.org()}`, body, );
    }

    deleteworkaddress(id: Number): Observable<any> {   
        return this.delete(`organizations/work-address/${id}/?organization_id=${this.org()}`, );
    }

    // Career-Page
    getCareerPages(): Observable<any> {
        return this.get(`organizations/hiring/career-page/?organization_id=${this.org()}`, );
    }

    updateCareerPage(id: Number, body: any): Observable<any> {
        return this.put(`organizations/hiring/career-page/${id}/?organization_id=${this.org()}`, body, );
    }

    deleteCareerPage(id: Number): Observable<any> {
        return this.delete(`organizations/hiring/career-page/${id}/?organization_id=${this.org()}`, );
    }


    //leave-Formate
    createLeaveFormate(body: any): Observable<any> {
        return this.post(`leave-format/?organization_id=${this.org()}`, body);
    }

    updateLeaveFormate(id: Number, body: any): Observable<any> {   
        return this.put(`leave-format/${id}/?organization_id=${this.org()}`, body, );
    }

    deleteLeaveFormate(id: Number): Observable<any> {   
        return this.delete(`leave-format/${id}/?organization_id=${this.org()}`, );
    }

    getLeaveFormate(searchText?: string, ordering?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&ordering=${ordering}`;
        }
        return this.get(`leave-format/${params}`);
    }

    //Leavetemplate

    createLeavetemplate(body: any): Observable<any> {
        return this.post(`leave-template/?organization_id=${this.org()}`, body);
    }

    updateLeavetemplate(id: Number, body: any): Observable<any> {   
        return this.put(`leave-template/${id}/?organization_id=${this.org()}`, body );
    }

    deleteLeavetemplate(id: Number): Observable<any> {   
        return this.delete(`leave-template/${id}/?organization_id=${this.org()}`, );
    }

    getLeavetemplate(searchText?: string, ordering?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`leave-template/${params}`);
    }

    //leave-type
    createLeaveType(body: any): Observable<any> {
        return this.post(`leave-types/?organization_id=${this.org()}`, body);
    }

    updateLeaveType(id: Number, body: any): Observable<any> {   
        return this.put(`leave-types/${id}/?organization_id=${this.org()}`, body, );
    }

    deleteLeaveType(id: Number): Observable<any> {   
        return this.delete(`leave-types/${id}/?organization_id=${this.org()}`, );
    }

    getMemberLeaves(): Observable<any> {
        return this.get(`member/leave/?organization_id=${this.org()}`);
    }

    getLeaveType(searchText?: string, ordering?: any, all_result?: boolean): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        if (all_result !== undefined){
            //Convert the boolean to a string before appending to params
            params +=`&all_result=${all_result.toString()}`;
        }
        return this.get(`leave-types/${params}`);
    }

    //request-type
    createRequestType(body: any): Observable<any> {
        return this.post(`request-types/?organization_id=${this.org()}`, body);
    }

    updateRequestType(id: Number, body: any): Observable<any> {   
        return this.put(`request-types/${id}/?organization_id=${this.org()}`, body);
    }

    deleteRequestType(id: Number): Observable<any> {   
        return this.delete(`request-types/${id}/?organization_id=${this.org()}`);
    }

    getRequestType(searchText?: string, ordering?: any, all_result?: boolean): Observable<any> {
        let params = `?organization_id=${this.org()}`;

        if (all_result !== undefined){
            //Convert the boolean to a string before appending to params
            params +=`&all_result=${all_result.toString()}`;
        }
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
          params += `&${ordering}`;
        }
              return this.get(`request-types/${params}`);
      }

       //payment-term
      
       createPaymentTerm(body: any): Observable<any> {
        return this.post(`payment-term/?organization_id=${this.org()}`, body);
    }

    updatePaymentTerm(id: Number, body: any): Observable<any> {   
        return this.put(`payment-term/${id}/?organization_id=${this.org()}`, body, );
    }

    getPaymentTerm(searchText?: string, ordering?: any,all_result?:any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (all_result){
            params +=`&all_result=${all_result}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`payment-term/${params}`);
    }

    // deletePaymentTerm(id: Number): Observable<any> {   
    //     return this.delete(`payment-term/${id}/?organization_id=${this.org()}`);
    // }

    //project-tax-class
    
    createtaxclass(body: any): Observable<any> {
        return this.post(`project-tax-class/?organization_id=${this.org()}`, body);
    }

    updatetaxclass(id: Number, body: any): Observable<any> {   
        return this.put(`project-tax-class/${id}/?organization_id=${this.org()}`, body, );
    }

    gettaxclass(searchText?: string, ordering?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`project-tax-class/${params}`);
    }

    // deleteTextclass(id: Number): Observable<any> {   
    //     return this.delete(`project-tax-class/${id}/?organization_id=${this.org()}`);
    // }
     
      //rate-category
    
      createRateCategory(body: any): Observable<any> {
        return this.post(`project-rate-category/?organization_id=${this.org()}`, body);
    }

    updateRateCategory(id: Number, body: any): Observable<any> {   
        return this.put(`project-rate-category/${id}/?organization_id=${this.org()}`, body, );
    }

    getRateCategory(searchText?: string, ordering?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`project-rate-category/${params}`);
    }
    // deleteRateCategory(id: Number): Observable<any> {   
    //     return this.delete(`project-rate-category/${id}/?organization_id=${this.org()}`);
    // }

    //Shift Time

    createShiftTime(body: any): Observable<any> {
        return this.post(`organizations/shift-time/?organization_id=${this.org()}`, body);
    }

    getShiftTime(searchText?: string, ordering?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`organizations/shift-time/${params}`);
    }
    updateShiftTime(id: Number, body: any): Observable<any> {   
        return this.put(`organizations/shift-time/${id}/?organization_id=${this.org()}`, body, );
    }

    //Asset Type
    getAssetType(searchText?: string, ordering?: any,all_result?:any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (all_result){
            params +=`&all_result=${all_result}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`asset/type/${params}`);
    }

    createAssetType(body: any): Observable<any> {
        return this.post(`asset/type/?organization_id=${this.org()}`, body);
    }

    updateAssetType(id: Number, body: any): Observable<any> {   
        return this.put(`asset/type/${id}/?organization_id=${this.org()}`, body);
    }

    deleteAssetType(id: Number): Observable<any> {   
        return this.delete(`asset/type/${id}/?organization_id=${this.org()}`);
    }

    //Asset-Category
    getAssetCategory(searchText?: string, ordering?: any,all_result?:any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (all_result){
            params +=`&all_result=${all_result}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`asset/category/${params}`);
    }

    createAssetCategory(body: any): Observable<any> {
        return this.post(`asset/category/?organization_id=${this.org()}`, body);
    }

    updateAssetCategory(id: Number, body: any): Observable<any> {   
        return this.put(`asset/category/${id}/?organization_id=${this.org()}`, body);
    }

    deleteAssetCategory(id: Number): Observable<any> {   
        return this.delete(`asset/category/${id}/?organization_id=${this.org()}`);
    }

    // Holiday Template
    getHolidayTemplate(searchText?: string, ordering?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (searchText) {
            params += `&search=${searchText}`;
        }
        if (ordering) {
            params += `&${ordering}`;
        }
        return this.get(`holiday-template/${params}`);
    }    

}
