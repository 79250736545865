import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from '../../common/pipe/filter.pipe';
import { SafePipe } from '../pipe/safePipe.pipe';
import { StripHtmlPipe } from '../pipe/strip-html.pipe';
import { NumberToWordsPipe } from '../pipe/number-to-words.pipe';
import { TaxSummaryDirective } from 'src/app/_metronic/kt/_utils/tax-summaryutil';
import { CapitalizeWordsDirective } from 'src/app/_metronic/kt/_utils/capitalizeWordsUtil';
import { PriceListDirective } from 'src/app/_metronic/kt/_utils/_PriceListUtils';
import { DropdownDirective } from 'src/app/_metronic/kt/_utils/_DropdownSearchUtil';

@NgModule({
  declarations: [
    FilterPipe,
    SafePipe,
    StripHtmlPipe,
    NumberToWordsPipe,
    TaxSummaryDirective,
    CapitalizeWordsDirective,
    PriceListDirective,
    DropdownDirective
  ],
  imports: [
    CommonModule,
  ],
  exports:[
    FilterPipe,
    SafePipe,
    StripHtmlPipe,
    CommonModule,
    NumberToWordsPipe,
    TaxSummaryDirective,
    CapitalizeWordsDirective,
    PriceListDirective,
    DropdownDirective
  ]
})
export class SharedModule {}