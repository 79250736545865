export enum RoutePath {
  Root = '',
  RoutePath_1 = 'auth',
  RoutePath_2 = 'error',
  RoutePath_3 = '404',
  RoutePath_4 = 'login',
  RoutePath_5 = 'verify-email',
  RoutePath_6 = ':verificationKey',
  RoutePath_7 = 'registration',
  RoutePath_8 = 'forgot-password',
  RoutePath_9 = 'change-password',
  RoutePath_10 = 'logout',
  RoutePath_11 = 'opportunity',
  RoutePath_12 = 'clients',
  RoutePath_13 = '500',
  RoutePath_14 = 'member',
  RoutePath_15 = 'set-up',
  RoutePath_16 = 'career-page',
  RoutePath_17 = 'evaluation',
  RoutePath_18 = 'evaluation-management',
  RoutePath_19 = 'JobPage',
  RoutePath_20 = 'candidates',
  RoutePath_21 = 'candidates-management',
  RoutePath_22 = 'templates',
  RoutePath_23 = 'template',
  RoutePath_24 = 'task',
  RoutePath_25 = 'integration',
  RoutePath_26 = 'addJob',
  RoutePath_27 = 'setUp',
  RoutePath_28 = 'questions',
  RoutePath_29 = 'candidate',
  RoutePath_30 = 'interview',
  RoutePath_31 = 'personal',
  RoutePath_32 = 'evaluations',
  RoutePath_33 = 'work',
  RoutePath_34 = 'salary',
  RoutePath_35 = 'offers',
  RoutePath_36 = 'careerPageView',
  RoutePath_37 = 'fill',
  RoutePath_38 = 'settings',
  RoutePath_39 = 'edit-details',
  RoutePath_40 = 'vertical',
  RoutePath_41 = 'add',
  RoutePath_42 = 'userProfile',
  RoutePath_43 = 'pip',
  RoutePath_44 = 'payslip',
  RoutePath_45 = 'overview',
  RoutePath_46 = 'on-boarding',
  RoutePath_47 = 'exit',
  RoutePath_48 = 'team-structure',
  RoutePath_49 = 'plan',
  RoutePath_50 = 'members',
  RoutePath_51 = 'timesheet',  
  RoutePath_52 = 'teams',
  RoutePath_53 = 'invoice-management',
  RoutePath_54 = 'phase',
  RoutePath_55 = 'project-invoice',
  RoutePath_56 = 'invoice',
  RoutePath_57 = 'all-request',
  RoutePath_58 = 'apply-leave',
  RoutePath_59 = 'view-request',
  RoutePath_60 = 'deduction',
  RoutePath_61 = 'salary-component',
  RoutePath_62 = 'purchase-orders',
  RoutePath_63 = 'payment',
  RoutePath_64 = 'followers',
  RoutePath_65 = 'feeds',
  RoutePath_66 = 'department',
  RoutePath_67 = 'designation',
  RoutePath_68 = 'work-address',
  RoutePath_69 = 'member-type',
  RoutePath_70 = 'leave-type',
  RoutePath_71 = 'request-type',
  RoutePath_72 = 'work-day',
  RoutePath_73 = 'rate-category',
  RoutePath_74 = 'tax-class',
  RoutePath_75 = 'payment-terms',
  RoutePath_76 = 'product',
  RoutePath_77 = 'orders',
  RoutePath_78 = 'customerOrder',
  RoutePath_79 = 'vendorOrder',
  RoutePath_80 = 'order',
  RoutePath_81 = 'customerDetails',
  RoutePath_82 = 'vendorDetails',
  RoutePath_83 = 'categories',
  RoutePath_84 = 'customers',
  RoutePath_85 = 'vendor',
  RoutePath_86 = 'barcode',
  RoutePath_87 = 'sales-set-up',
  RoutePath_88 = 'home',
  RoutePath_89 = 'dashboard',
  RoutePath_90 = 'organization',
  RoutePath_91 = 'website-builder',
  RoutePath_92 = 'hiring',
  RoutePath_93 = 'catalog',
  RoutePath_94 = 'access-management',
  RoutePath_95 = 'reports',
  RoutePath_96 = 'subscription',
  RoutePath_97 = 'request',
  RoutePath_98 = 'suggestions',
  RoutePath_100 = 'crm',
  RoutePath_101 = 'project-management',
  RoutePath_102 = 'social',
  RoutePath_103 = 'builder',
  RoutePath_104 = 'attendance',
  RoutePath_105 = 'task-details',
  RoutePath_106 = 'pos-invoice',
  RoutePath_107 = 'vendor-invoice',
  RoutePath_108 = 'vendor-orders',
  RoutePath_109 = 'customer-orders',
  RoutePath_110 = 'report',
  RoutePath_1101 = 'home',
  RoutePath_1102 = 'detail-report',
  RoutePath_111 = 'attendance-summary-report',
  RoutePath_112 = 'leave-summary-report',
  RoutePath_113 = 'shift-time',
  RoutePath_114 = 'mart-inquiry',
  RoutePath_115 = 'asset-type',
  RoutePath_116 = 'asset-category',
  RoutePath_117 = 'track-visit',
  RoutePath_118 = 'add-track-visit',
  RoutePath_119 = 'asset-management',
  RoutePath_120 = 'asset',
  RoutePath_121 = 'asset-assignment',
  RoutePath_122 = 'request',
  RoutePath_123 = 'mart',
  RoutePath_125 = 'organization-branch',
  RoutePath_124 = 'sales-account',
  RoutePath_126 = 'integrations',
  RoutePath_129 = 'asset-detail',
  RoutePath_133 = 'availability',
  RoutePath_134 = 'appointment',
  RoutePath_135 = 'event-types',
  RoutePath_136 = 'meetings',
  RoutePath_130 = 'production-management',
  RoutePath_131 = 'bill-of-material',
  RoutePath_132 = 'production',
  RoutePath_137 = 'view-booking-page',
  RoutePath_138 = 'production-order',
  RoutePath_139 = 'create-production-order',
  RoutePath_140 = 'purchase',
  RoutePath_141 = 'inquiry',  
  RoutePath_143 = 'quotation',
  RoutePath_144 = 'quotation/add',
  RoutePath_145 = 'purchase-order',  
  RoutePath_147 = 'inward',
  RoutePath_149 = 'grn',  
  RoutePath_151 = 'public-view-inquiry',
  RoutePath_152 = 'public-inquiry',
  RoutePath_153 = 'public-invoice',
  RoutePath_154 = 'sales-inquiry',  
  RoutePath_155 = 'sales-quotation',  
  RoutePath_156 = 'order-confirmation',  
  RoutePath_157 = 'proforma-invoice',  
  RoutePath_158 = 'challan',  
  RoutePath_159 = 'tax-invoice',  
  RoutePath_160 = 'sales-return',  
  RoutePath_161 = 'sales-workflow',
  RoutePath_162 = 'stock-adjustment',
  RoutePath_163 = 'stock-transfer',
  RoutePath_164 = 'organization-set-up',
  RoutePath_165 = 'hiring-set-up',
  RoutePath_166 = 'set-up-config',
  RoutePath_167 = 'public-purchase-order',
  RoutePath_168 = 'amount-payable',
  RoutePath_169 = 'amount-receivable',
  RoutePath_170 = 'expense',
  RoutePath_171 = 'receipt-voucher',
  RoutePath_172 = 'credit-note',
  RoutePath_173 = 'debit-note',
  RoutePath_174 = 'bank-account',
  RoutePath_175 = 'relation',
  RoutePath_176 = 'indent',
  RoutePath_177 = 'configuration',
  RoutePath_178 = 'workflow-approval',
  RoutePath_179 = "price-list",
  RoutePath_180 = "price-list-item",
  RoutePath_181 = "account",
  RoutePath_182 = "journal-entry",
  RoutePath_183 = "expense-entry",
  RoutePath_184 = "cash-receipts",
  RoutePath_185 = "cash-payments",
  RoutePath_186 = "adjustment-entry",
  RoutePath_187 = "chart-of-account",
  RoutePath_188 = "order-receipt-voucher",
  RoutePath_192 = "marketing",
  RoutePath_193 = "whatsapp-marketing",
  RoutePath_191 = "broadcast-template",
  RoutePath_189 = "e-Way-bill",
  RoutePath_190 = 'attendance-management',
  RoutePath_194 = 'org-attendance',
  RoutePath_195 = 'org-request',
  RoutePath_196 = 'account-ledger',
  RoutePath_197 = 'user-group',  
  RoutePath_198 = 'inquiry',
  RoutePath_199 = 'holidays',


  Id = ':id',
  Token = ':token',
  Full = 'full',
  Wildcard = '**',
  Print = 'print',
  Edit = 'edit',
  ADD = 'add',
  DETAILS = 'details'
}