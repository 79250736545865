import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends ConnectBaseService {


  createProduct(body: any): Observable<any> {
    return this.post(`salesproduct/?organization_id=${this.org()}`, body);
  }

  getProductRetrieve(id: any): Observable<any> {
    return this.get(`salesproduct/${id}/?organization_id=${this.org()}`);
  }

  getVendorBarcode(all_result?: boolean, not_assigned?: boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result !== undefined) {
      params += `&all_result=${all_result}`;
    }
    if (not_assigned !== undefined) {
      params += `&not_assigned=${not_assigned}`;
    }
    return this.get(`sales/organization-barcode/${params}`);
  }

  getProduct(filterField?:any,searchText?: any, ordering?: any,customeParams?:any,all_result?: boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (filterField) {
      params += `&${filterField}`;
    }
    if (searchText) {
      params += `&search=${searchText}`;
    }
    if (ordering) {
      params += `&${ordering}`;
    }
    if (customeParams) {
      params += `&${customeParams}`;
    }
    if (all_result !== undefined) {
      params += `&all_result=${all_result}`;
    }
    return this.get(`salesproduct/${params}`);
  }

  getproducvariation(all_result?: boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result !== undefined) {
      params += `&all_result=${all_result}`;
    }
    return this.get(`salesproduct-variation/${params}`)
  }

  updateProduct(body: any, id: any): Observable<any> {
    return this.put(`salesproduct/${id}/?organization_id=${this.org()}`, body);
  }

  deleteProduct(id: any): Observable<any> {
    return this.delete(`salesproduct/${id}/?organization_id=${this.org()}`);
  }

  getproducttexclass(all_result?: boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result !== undefined) {
      params += `&all_result=${all_result}`;
    }
    return this.get(`salesproduct-taxclass/${params}`)
  }

  // Product-Addons
  getProductAddons(all_result?: boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result !== undefined) {
      params += `&all_result=${all_result}`;
    }
    return this.get(`addon-products/${params}`)
  }

  createProductAddons(body: any): Observable<any> {
    return this.post(`product-addon/?organization_id=${this.org()}`, body);
  }

  createProductAddonsPayment(body: any): Observable<any> {
    return this.post(`product-addon/payment/?organization_id=${this.org()}`, body);
  }

  convertToValueLabelArray(inputArray: any[], idKey: string, nameKey: string): any[] {
    if (nameKey.includes(',')) {
      return inputArray.map(item => {
        let label = '';
        const keys = nameKey.split(',');
        for (const key in keys) {
          label += `${item[keys[key]]} `
        }
        return {
          value: item[idKey],
          label: label
        }
      });
    } else {
      return inputArray.map(item => ({
        value: item[idKey],
        label: item[nameKey]
      }));
    }
  }

  getMarketingProduct(all_result?: boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result !== undefined) {
      params += `&all_result=${all_result}`;
    }
    params += `&store_type=1`
    return this.get(`marketing-salesproduct/${params}`)
  }

}
